import "focus-visible";

let i = 0;
(function cursor() {
	document.body.style.setProperty("--pointer", `var(--c${i})`);
	i++;
	if (i === 7) {
		i = 0;
	}
	setTimeout(cursor, 166);
})();

history.replaceState(null, "", "index.htm");

// const modal = document.querySelector(".modal") as HTMLElement;
// document.body.style.overflowY = "hidden";
// modal.querySelector("button:first-of-type").addEventListener("click", () => {
// 	document.body.style.overflowY = "";
// 	modal.style.display = "none";
// });
